<ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item active">Impressum</li>
</ol>
 <div class="container" style="text-align: left;">
    <h1>Impressum</h1>
    <p>Andreas Will<br />
        IT-Consulting Will<br />St&ouml;pfertal 49<br />98544 Zella-Mehlis</p>
    <p>Telefon: +49 36843 60140<br />
       Telefax: +49 36843 60129<br />
       E-Mail: info[at]itconsulting-will.de<br />
    </p>
    <h2>Hinweis gem&auml;&szlig; Online-Streitbeilegungs-Verordnung</h2>
    <p>Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz der Europ&auml;ischen
        Online-Streitbeilegungs-Plattform hinzuweisen, die f&uuml;r die Beilegung von Streitigkeiten genutzt werden kann,
        ohne dass ein Gericht eingeschaltet werden muss. F&uuml;r die Einrichtung der Plattform ist die Europ&auml;ische
        Kommission zust&auml;ndig.<br />
         Die Europ&auml;ische Online-Streitbeilegungs-Plattform ist hier zu finden: <a href="http://ec.europa.eu/odr"
            target="_blank" rel="nofollow">http://ec.europa.eu/odr</a>. 
            Unsere E-Mail lautet: info[at]itconsulting-will.de</p>
    <p>Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen der
        Europ&auml;ischen Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur Kontaktaufnahme bitte unsere 
        obige E-Mail und Telefonnummer.</p>
    
    <h2>Disclaimer &amp; rechtliche Hinweise</h2>

    <h3>1. Warnhinweis zu Inhalten</h3>
    <p>Die kostenlosen und frei zug&auml;nglichen Inhalte dieser Webseite wurden mit gr&ouml;&szlig;tm&ouml;glicher Sorgfalt 
    erstellt. Der Anbieter dieser Webseite &uuml;bernimmt jedoch keine Gew&auml;hr f&uuml;r die Richtigkeit und 
    Aktualit&auml;t der bereitgestellten kostenlosen und frei zug&auml;nglichen journalistischen Ratgeber und Nachrichten. 
    Namentlich gekennzeichnete Beitr&auml;ge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des 
    Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei zug&auml;nglichen Inhalte kommt keinerlei 
    Vertragsverh&auml;ltnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen 
    des Anbieters.</p>

    <h3>2. Externe Links</h3>
    <p>Website enth&auml;lt Verkn&uuml;pfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der 
    Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verkn&uuml;pfung der externen Links die fremden
    Inhalte daraufhin &uuml;berpr&uuml;ft, ob etwaige Rechtsverst&ouml;&szlig;e bestehen. Zu dem Zeitpunkt waren keine 
    Rechtsverst&ouml;&szlig;e ersichtlich.
    Der Anbieter hat keinerlei Einfluss auf die aktuelle und zuk&uuml;nftige Gestaltung und auf die Inhalte der 
    verkn&uuml;pften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis 
    oder Link liegenden Inhalte zu Eigen macht. Eine st&auml;ndige Kontrolle der externen Links ist f&uuml;r den Anbieter 
    ohne konkrete Hinweise auf Rechtsverst&ouml;&szlig;e nicht zumutbar. Bei Kenntnis von Rechtsverst&ouml;&szlig;en 
    werden jedoch derartige externe Links unverz&uuml;glich gel&ouml;scht.</p>

    <h3>3. Urheber- und Leistungsschutzrechte</h3>
    <p>Die auf dieser Website ver&ouml;ffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht. 
    Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen
    Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere f&uuml;r Vervielf&auml;ltigung, 
    Bearbeitung, &Uuml;bersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen 
    elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte
    Vervielf&auml;ltigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar.
    Lediglich die Herstellung von Kopien und Downloads f&uuml;r den pers&ouml;nlichen, privaten und nicht kommerziellen 
    Gebrauch ist erlaubt.</p>
    <p>Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zul&auml;ssig.</p>
    
    <h3>4. Besondere Nutzungsbedingungen</h3>
    <p>Soweit besondere Bedingungen f&uuml;r einzelne Nutzungen dieser Website von den vorgenannten Paragraphen abweichen, wird
    an entsprechender Stelle ausdr&uuml;cklich darauf hingewiesen. In diesem Falle gelten im jeweiligen Einzelfall die
    besonderen Nutzungsbedingungen.</p>
    <p>Quelle: <a href="https://www.juraforum.de/impressum-generator/">Impressum Vorlage von JuraForum.de</a></p>
    <h1>.</h1>
</div>